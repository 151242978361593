import axios from 'axios';

const translatePlugin = {
    install(vue){
        vue.prototype.$translateToKannada = async function(value) {
            const options = {
              method: 'POST',
              url: 'https://google-translate113.p.rapidapi.com/api/v1/translator/text',
              headers: {
                'x-rapidapi-key': 'a7442cf8e9mshcf08d413ffbe684p1ce40djsnc15622d237f1',
                'x-rapidapi-host': 'google-translate113.p.rapidapi.com',
                'Content-Type': 'application/json',
              },
              data: {
                from: 'en',
                to: 'kn',
                text: value,
              },
            };
          
            try {
              const response = await axios.request(options);
              return response.data.trans;
            } catch (error) {
              console.error('Error converting to Kannada:',error);
              return value;
            }
        };

        // vue.prototype.$convertToKannada = async function(name) {
        //   try {
        //         const nameArray = name.split(',');
        //         let convertedNameArray = await Promise.all(nameArray.map(async element => {
        //         const url = `https://inputtools.google.com/request?itc=kn-t-i0-und&text=${element}`;
        //         const response = await axios.get(url);
        //         const convertedName = response.data[1][0][1][0];
        //         return convertedName;
        //       }));
        //       let joinedNameString = convertedNameArray.join(', ');
        //       return joinedNameString;
        //   } catch (error) {
        //       console.error('Error converting to Kannada:', error);
        //       return name; // Fallback to the original name on error
        //   }
        // };
        vue.prototype.$convertToKannada = async function(name) {
          try {
              // Split the name by '||' first, preserving the '||'
              const parts = name.split('||').map(part => part.trim());
              let convertedParts = await Promise.all(parts.map(async part => {
                  // Further split by comma if necessary
                  const nameArray = part.split(',').map(element => element.trim());
                  let convertedNameArray = await Promise.all(nameArray.map(async element => {
                      const url = `https://inputtools.google.com/request?itc=kn-t-i0-und&text=${element}`;
                      const response = await axios.get(url);
                      const convertedName = response.data[1][0][1][0];
                      return convertedName;
                  }));
                  return convertedNameArray.join(', ');
              }));
              // Join the converted parts back together with '||'
              let joinedNameString = convertedParts.join(' || ');
              return joinedNameString;
          } catch (error) {
              console.error('Error converting to Kannada:', error);
              return name; // Fallback to the original name on error
          }
      };      

    }
    
};
export default translatePlugin;