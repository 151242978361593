import Toastify from 'toastify-js'

const snotify = {

    install(vue){
        vue.prototype.$error = async function  success(message, time = 3000) {
                Toastify({
                    text: message,
                    duration: time,
                    close: true,
                    gravity: 'top',
                    position: 'right',
                    backgroundColor: 'red',
                    stopOnFocus: true, // Prevents dismissing of toast on hover
                }).showToast()
        }
        vue.prototype.$success=async function success(message, time = 3000) {
                            Toastify({
                                text: message,
                                duration: time,
                                close: true,
                                gravity: 'top',
                                position: 'right',
                                backgroundColor: 'green',
                                stopOnFocus: true, // Prevents dismissing of toast on hover
                            }).showToast()
        }
        vue.prototype.$warning=async function warning(message) {
                            Toastify({
                                text: message,
                                duration: 3000,
                                close: true,
                                gravity: 'top',
                                position: 'right',
                                backgroundColor: 'orange',
                                stopOnFocus: true, // Prevents dismissing of toast on hover
                            }).showToast()
        }
    }
}

export default snotify;