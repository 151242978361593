require("./bootstrap");

import { App, plugin } from "@inertiajs/inertia-vue";
import Vue from "vue";
import VueMeta from "vue-meta";
import vSelect from "vue-select";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// import Vue from "vue";
import FlashMessage from "@smartweb/vue-flash-message";
import VueSimpleAlert from "vue-simple-alert";
import "vue-select/dist/vue-select.css";
import Pagination from "./Layouts/Pagination.vue";
import helpers from './plugins/helpers';
import translatePlugin from "./plugins/translate";
import snotify from "./plugins/snotify";
import 'toastify-js/src/toastify.css';
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";



Vue.mixin({
    methods: {
        route
    }
});
const customPlugin = {
    install () {
      Vue.helpers = helpers
      Vue.prototype.$helpers = helpers
    //   Vue.toast=Toastify;
    //   Vue.prototype.$toast = Toastify;
    }
  }
Vue.use(translatePlugin);
Vue.use(plugin);
Vue.use(VueMeta);
Vue.use(VueSidebarMenu);
Vue.use(snotify);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(FlashMessage);
Vue.use(VueSimpleAlert);
Vue.component("v-select", vSelect);
Vue.component("sidebar-menu", VueSidebarMenu);


  
  


Vue.use(Pagination);
Vue.use(customPlugin)
const el = document.getElementById("app");

new Vue({
    metaInfo: {
        titleTemplate: title => (title ? `${title}` : "Dashboard")
    },
    render: h =>
        h(App, {
            props: {
                initialPage: JSON.parse(app.dataset.page),
                resolveComponent: name =>
                    import(`./Pages/${name}`).then(module => module.default)
            }
        })
}).$mount(app);

Vue.prototype.trans = string => _.get(window.i18n, string);
